<template>
  <div>
    <el-dialog class="public-dialog" :title="title" :center="true" :visible.sync="show" :width="width" @close="onClose" :close-on-click-modal="false">
      <div slot="title" v-if="slot_title"><slot name="title"></slot></div>
      <slot></slot>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "50%",
    },
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "dialog",
    },
    slot_title: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.open
    };
  },
  watch: {
    open(newVal) {
      this.show = newVal
    }
  },
  methods: {
    onClose() {
      this.$emit("onClose")
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.public-dialog {
  .el-dialog {
    border-radius: 20px;
    // overflow: hidden;
    .el-dialog__header {
      height: 60px;
      padding: 0;
      background-color: #3489fe;
      border-radius: 20px 20px 0 0;
      .el-dialog__title {
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        line-height: 60px;
      }
      .el-dialog__headerbtn {
        .el-dialog__close {
          font-size: 20px;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}
/deep/ .el-dialog {
  .el-dialog__body {
    padding: 24px 24px 30px;
    font-size: 16px;
    color: #333;
  }
}
</style>